<template>
  <div>
    <section class="section">
      <div class="container mt-60">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4">
            <library :content.sync="content" />
          </div>
          <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8">
            <div class="section-title">
              <div class="media features">
                <div class="icons m-0 rounded h1 text-primary px-3">
                  <i class="uil uil-video"></i>
                </div>
              </div>
              <h4 class="title">จัดการเรียนสด (Live)</h4>
              <p class="border-bottom"></p>
            </div>

            <div class="section-title">
              <h5 class="text-primary">วิธีสร้างการเรียนสด</h5>
            </div>
            <!-- header -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">1</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">กดปุ่ม "Add Live"</h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal1>
                  <img
                    src="/images/screen/course/live/create/1.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal1"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/course/live/create/1.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12 mt-2">
                <div class="media features mb-3">
                  <div
                    class="icon text-center rounded-circle text-primary mr-3"
                  >
                    <h3 class="mb-0">2</h3>
                  </div>
                  <div class="media-body my-auto">
                    <h4 class="title mb-0">
                      ให้ผู้ใช้เพิ่มข้อมูลในแบบฟอร์มเรียนสด และกดปุ่มบันทึก
                    </h4>
                  </div>
                </div>
                <a href="javascript: void(0);" v-b-modal.modal2>
                  <img
                    src="/images/screen/course/live/create/2.png"
                    class="img-fluid mx-auto rounded-md shadow-lg d-block"
                  />
                </a>
                <b-modal
                  id="modal2"
                  hide-footer
                  hide-header
                  size="xl"
                  header-close-variant="white"
                  header-class="border-0"
                  content-class="border-0"
                  centered
                >
                  <img
                    src="/images/screen/course/live/create/2.png"
                    class="img-fluid mx-auto shadow-lg d-block"
                  />
                </b-modal>
              </div>
            </div>
            <!--end row-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import library from "@/views/library/library.vue";
export default {
  data() {
    return {
      content: "liveCreate",
    };
  },
  components: {
    library,
  },
};
</script>
